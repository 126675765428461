<template>
  <div class="flex flex-col w-full my-2 text-input">
    <div class="flex items-center justify-between w-full">
      <p class="px-3 text-lg font-extrabold text-left text-primary">{{label}}</p>
      <check-box
        @click="$emit('click')"
        v-if="canDelete"
        class="mr-4"
        :checked="checked"
        :color="color"
      />
    </div>
  </div>
</template>

<script>
import CheckBox from '@/components/shared/inputs/CheckBox.vue';

export default {
  name: 'RadioButton',
  props: {
    label: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    canDelete: {
      type: Boolean,
      requried: true,
    },
  },
  components: {
    CheckBox,
  },
};
</script>
