<template>
    <interior-page-content-container
      nav-selection=""
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="true"
      :modal-show="modal.show"
      :loading="loading"
    >
      <template #modal>
        <modal-confirmation
          title="Delete Room?"
          description="If you delete the room all of it's data will be lost!"
          text-confirm="Delete"
          text-cancel="Cancel"
          @cancel="modalCancel"
          @confirm="modalConfirm"
        />
      </template>

      <template #title>
        Room Settings
      </template>

      <template #content>
        <div class="`
          flex flex-col items-stretch self-center h-full pt-10
          w-5/6 phone:w-3/4 md:w-2/3 lg:w-3/5 max-w-md
          mb-10 pb-10
        `">

          <!-- Image and name -->
          <div class="flex items-center flex-shrink-0 w-full mb-4">
            <circular-image-component
              :picture="picture"
              class="flex-shrink-0"
            />
            <div class="flex flex-col h-16 pl-3 text-left">
              <p class="text-2xl font-extrabold text-primary">
                {{room.name}}
              </p>
              <p class="text-sm text-content">
                *Landscape Pictures will display best
              </p>
            </div>
          </div>

          <!-- Project info -->
          <div class="w-full p-3 mb-4 rounded-lg bg-backfill">
            <text-input-component
              @save="triggerUpdate"
              :value.sync="room.name"
              :valid="room.name.length > 0"
              label="Name"
              :mode="projectPermissions !== 'basic' ? 'edit' : 'display'"
            />
            <text-input-component
              @save="triggerUpdate"
              label="Floor"
              :value.sync="room.floor"
              :mode="projectPermissions !== 'basic' ? 'edit' : 'display'"
              :valid="room.floor.length > 0"
            />
          </div>

          <!-- Actions -->
          <div
            v-if="projectPermissions !== 'basic'"
            class="w-full p-3 mb-4 rounded-lg bg-backfill"
          >
            <button-inline
              @click="takePicture"
              label="Picture"
              :value="'Update'"
              :disabled="false"
            />
            <input
              @change="replacePicture"
              v-show='false' type="file" accept="image/*"
            />
          </div>

          <!-- Rooms -->
          <div v-if="room.items.length > 0" class="w-full mb-4 ">
            <p class="ml-5 text-xl font-extrabold text-left text-content">Items</p>
            <div class="flex flex-col w-full p-3 rounded-lg bg-backfill ">

              <div
                v-for="(item, index) in room.items"
                :key="item.name"
                class="w-full"
              >
                <radio-button
                  @click="itemClicked(item.id)"
                  :label="item.name"
                  :checked="itemsToDelete.includes(item.id)"
                  :canDelete="editItems"
                  color="red"
                  class="text-white"
                />
                <div v-if="index !== room.items.length -1" class="border border-contrast">
                </div>
              </div>

              <div
                v-if="projectPermissions !== 'basic'"
                class="flex justify-around w-full"
              >
                <button-small
                  @click="toggleRoomEdit"
                  color="secondary"
                  class="self-center"
                >
                  {{editItems ? "Cancel" : "Edit" }}
                </button-small>

                <button-small
                  v-if="editItems"
                  @click="deleteItems"
                  color="red"
                  class="self-center"
                >
                  Delete
                </button-small>
              </div>

            </div>
          </div>

          <button-large
            v-if="projectPermissions !== 'basic'"
            @click="deleteButton"
            color="red"
            class="self-center"
          >
            Delete
          </button-large>
          <div class="h-10 opacity-0">space...</div>

        </div>
      </template>
    </interior-page-content-container>
</template>

<script>

import { mapRoomFields } from '@/store/mappers';
import {
  ROOM_GET, ITEM_DELETE, ROOM_PUT,
  ROOM_DELETE,
} from '@/store/actions';
import { PROJECT_PERMISSIONS_CURRENT } from '@/store/getters';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import ModalConfirmation from '@/components/shared/inputs/ModalConfirmation.vue';
import CircularImageComponent from '@/components/shared/general/CircularImageComponent.vue';
import TextInputComponent from '@/components/shared/inputs/TextInputComponent.vue';
import ButtonInline from '@/components/shared/Buttons/ButtonInline.vue';
import RadioButton from '@/components/shared/general/RatioButton.vue';
import ButtonSmall from '@/components/shared/Buttons/ButtonSmall.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'RoomSettings',
  components: {
    InteriorPageContentContainer,
    ModalConfirmation,
    CircularImageComponent,
    TextInputComponent,
    ButtonInline,
    RadioButton,
    ButtonSmall,
    ButtonLarge,
  },
  data() {
    return {
      toDelete: false,
      itemsToDelete: [],
      roomValidity: {
        nameValid: true,
        floorValid: true,
      },
      modal: {
        show: false,
        forDiscardChanges: true,
      },
      editItems: false,
      loading: true,
      saving: false,
      newPicture: null,
    };
  },
  computed: {
    ...mapRoomFields(['room', 'rooms']),
    projectPermissions() {
      return this.$store.getters[PROJECT_PERMISSIONS_CURRENT];
    },
    valid() {
      return this.roomValidity.nameValid && this.roomValidity.floorValid;
    },
    picture() {
      if (this.newPicture) return URL.createObjectURL(this.newPicture);
      return this.room.picture.url;
    },
    defaultBackRoute() {
      return {
        name: 'Room',
        params: {
          project_id: this.$route.params.project_id,
          room_id: this.$route.params.room_id,
        },
      };
    },
  },
  watch: {
    newPicture() {
      this.triggerUpdate();
    },
  },
  created() {
    this.loading = !this.rooms[this.$route.params.room_id];
    this.room = this.rooms[this.$route.params.room_id] || {};
    this.$store.dispatch(ROOM_GET, {
      projectId: this.$route.params.project_id,
      roomId: this.$route.params.room_id,
    })
      .catch(() => this.$router.push({ name: 'PageNotFound' }))
      .finally(() => { this.loading = false; });
  },
  methods: {
    triggerUpdate() {
      this.saving = true;
      this.put()
        .then(() => {
          this.saving = false;
        });
    },
    toggleRoomEdit() {
      this.editItems = !this.editItems;
      this.itemsToDelete = [];
    },
    put() {
      const putRoom = {
        name: this.room.name,
        floor: this.room.floor,
      };
      if (this.newPicture) putRoom.picture = this.newPicture;
      return this.$store.dispatch(ROOM_PUT, {
        projectId: this.$route.params.project_id,
        roomId: this.$route.params.room_id,
        room: putRoom,
      })
        .catch(() => {
          console.log('The room was not updated');
        });
    },
    async deleteItems() {
      const { items } = this.room;
      const promises = [];
      this.saving = true;
      this.itemsToDelete.forEach((itemId) => {
        promises.push(
          this.$store.dispatch(ITEM_DELETE, {
            projectId: this.$route.params.project_id,
            roomId: this.$route.params.room_id,
            itemId,
          }),
        );
      });
      await Promise.all(promises);
      this.saving = false;
      this.room.items = items.filter((item) => !this.itemsToDelete.includes(item.id));
      this.itemsToDelete = [];
    },
    deleteButton() {
      this.modal.forDiscardChanges = false;
      this.modal.show = true;
    },
    itemClicked(index) {
      if (this.itemsToDelete.includes(index)) {
        this.itemsToDelete = this.itemsToDelete.filter((itemIndex) => itemIndex !== index);
      } else this.itemsToDelete.push(index);
    },
    replacePicture(event) {
      // eslint-disable-next-line prefer-destructuring
      this.newPicture = event.target.files[0];
    },
    takePicture() {
      document.querySelector('input[type="file"]').click();
    },
    modalConfirm() {
      this.actionComplete = true;
      this.$store.dispatch(ROOM_DELETE, {
        projectId: this.$route.params.project_id,
        roomId: this.$route.params.room_id,
      })
        .then(() => {
          this.$router.push({
            name: 'Project',
            params: {
              project_id: this.$route.params.project_id,
              tab: 'Declutter',
            },
          });
        });
    },
    modalCancel() {
      this.modal.show = false;
    },
    createItem() {
      this.$router.push({
        name: 'ItemCreate',
        params: {
          project_id: this.$route.params.project_id,
          room_id: this.$route.params.room_id,
        },
      });
    },
    backNav() {
      if (!this.saving) this.$router.push(this.defaultBackRoute);
    },
  },

};
</script>
