<template>
  <div
    @click="emitClick"
    class="`
      flex items-center justify-center w-5 h-5 text-white rounded-full check-box
    `"
    :class="[`bg-${color}-700`, {'cursor-pointer' : !disable}]"
  >
    <div
      class="flex items-center justify-center w-3 h-3 bg-white rounded-full"
    >
      <div
        v-if="checked"
        class="flex items-center justify-center w-2 h-2 rounded-full"
        :class="`bg-${color}-700`"
      >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckBox',
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'green',
      validator: (val) => ['green', 'red'].includes(val),
    },
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    emitClick() {
      if (!this.disable) this.$emit('click');
    },
  },
};
</script>

<style>

</style>
