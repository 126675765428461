<template>
  <button
    @click="$emit('click')"
    :disabled="disabled"
    :class="[
      disabled ?
        'bg-gray-600 cursor-default text-gray-300' :
        bgColor
    ]"
    class="`
      flex flex-shrink-0 flex-grow-0 items-center
      px-3 py-1 font-bold text-white rounded-lg
      focus:outline-none
    `"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonSmall',
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    color: {
      type: String,
      required: true,
      validate: (val) => ['green', 'red', 'primary', 'secondary'].inlcudes(val),
    },
  },
  computed: {
    bgColor() {
      if (['green', 'red'].filter((c) => c === this.color).length > 0) {
        return `bg-${this.color}-500`;
      }
      return `bg-${this.color}`;
    },
  },
};
</script>
